import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";

export interface Year {
  id: string;
  display_name: string;
}

export function NewYearHandbook(props: AutocompleteProps<Year>) {
  const items: Year[] = [
    { id: '2023', display_name: '2023' },
    { id: '2024', display_name: '2024' },
    { id: '2025', display_name: '2025' },
    { id: '2026', display_name: '2026' },
    { id: '2027', display_name: '2027' }
  ];

  return (
    <Autocomplete
      defaultItems={items}
      label="Год"
      placeholder="Выберите год"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={item.id}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}