import { Button, Input } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { TemplateVariable } from "../../../../api/templates";

interface FormValues {
  [key: string]: string;
}

export function DocumentTemplatePage() {
  const { id } = useParams<{ id: string }>();
  const [variables, setVariables] = useState<TemplateVariable[]>([]);
  const { control, handleSubmit, setValue } = useForm<FormValues>();

  const downloadFile = (fileUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (id) {
      api.documents.getTemplateVariables(Number(id))
        .then(res => {
          setVariables(res.data.variables);
          res.data.variables.forEach((variable: TemplateVariable) => {
            setValue(variable.variable, "");
          });
        })
        .catch(err => {
          toast.error("Не удалось загрузить переменные шаблона.");
          console.error(err);
        });
    }
  }, [id, setValue]);

  const onSubmit = (data: FormValues) => {
    if (id) {
      api.documents.fillTemplate({
        template_id: Number(id),
        values: data,
      })
        .then(res => {
          toast.success("Шаблон успешно заполнен!");
          downloadFile(res.document_link, res.document_link.split("/")[-1]);
        })
        .catch(err => {
          toast.error("Произошла ошибка при заполнении шаблона.");
          console.error(err);
        });
    }
  };

  return (
    <div className="flex flex-col gap-2 p-2 md:p-4 w-full overflow-auto">
      <span className="text-2xl font-bold">Заполнение шаблона</span>
      <span className="text-sm max-w-xl">
        Заполните значения, которые Вы хотите вставить в документ.
      </span>
      <span className="text-sm max-w-xl flex flex-col gap-2">
        Обратите внимание на системные переменные, которые подставляются автоматически:
        <ul className="flex flex-col gap-2">
          <li><b className="font-semibold">document_price_text</b> - в случае, если в документе есть переменная document_price, заполняется текстовым обозначением вышеуказанной суммы</li>
          <li><b className="font-semibold">document_date</b> - автоматически подставляется дата заполнения документа</li>
        </ul>
      </span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-3 p-3 max-w-xl">
          {variables.map((variable) => (
            <div key={variable.id} className="flex flex-col gap-1">
              <Controller
                name={variable.variable}
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    variant="bordered"
                    label={variable.description || variable.variable}
                    placeholder={`Введите ${variable.description || variable.variable}`}
                  />
                )}
              />
            </div>
          ))}
          <Button type="submit" size="md" color="primary" variant="solid" className="w-full">Готово</Button>
        </div>
      </form>
    </div>
  );
}