import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Ticket {
  id: number,
  title: string,
  description: string,
  status: string,
  category: number,
  office: number,
  user: number,
  created_at: string,
  updated_at: string
}

interface TicketCreate {
  title: string;
  description: string;
  status: string;
  category: number;
  office: number;
  user: number;
}

interface TicketUpdate extends Partial<TicketCreate> {};

export interface TicketMessage {
  id: number;
  ticket_id: number;
  message: string;
  sender: string;
  created_at: string;
}

interface CreateTicketMessage {
  message: string;
  sender: string;
  ticket_id: string;
}

export interface TicketCategory {
  id: number;
  display_name: string;
}

export function TicketsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/tickets/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Ticket[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/tickets/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Ticket>;
    },
    create: async (userData: TicketCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/tickets/?business_profile_id=${businessProfileId}`, userData, true) as ApiResponse<Ticket>;
    },
    update: async (id: number, userData: TicketUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/tickets/${id}?business_profile_id=${businessProfileId}`, userData, true) as ApiResponse<Ticket>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/tickets/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    messages: {
      list: async (id: number) => {
        const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
        return await crud.get(`${baseUrl}/admin/tickets/${id}/messages?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<TicketMessage[]>;
      },
      create: async (id: number, messageData: CreateTicketMessage) => {
        const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
        return await crud.post(`${baseUrl}/admin/tickets/${id}/messages?business_profile_id=${businessProfileId}`, messageData, true) as ApiResponse<TicketMessage>;
      }
    },
    categories: {
      list: async () => {
        const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
        return await crud.get(`${baseUrl}/admin/tickets/categories?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<TicketCategory[]>;
      },
      get: async (id: number) => {
        const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
        return await crud.get(`${baseUrl}/admin/tickets/categories/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<TicketCategory>;
      }
    }
  }
}
