import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { OfficeLocation } from "../../../../api/locations";
import { useNavigate } from "react-router-dom";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

const editLocationFields: InputField[] = [
  {
    label: "Название локации",
    name: "display_name",
    placeholder: "Коворкинг на Мясницкой",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "address",
    placeholder: "ул Краснобогатырская...",
    type: "text",
    isRequired: true
  }, {
    label: "Город",
    name: "city",
    placeholder: "Москва",
    type: "text",
    isRequired: true
  }, {
    label: "Станция метро",
    name: "metro_station",
    placeholder: "Черкизовская",
    type: "text",
    isRequired: true
  }, {
    label: "Район",
    name: "district",
    placeholder: "Преображенский",
    type: "text",
    isRequired: true
  }
]

export function EditLocationModal({ disclosure, location }: { disclosure: ReturnType<typeof useDisclosure>, location: OfficeLocation | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: { display_name: string, address: string, city: string, metro_station: string, district: string }) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locations.update(location!.id, {
        display_name: data.display_name,
        address: data.address,
        city: data.city,
        metro_station: data.metro_station,
        district: data.district,
        business_profile: businessProfile.id
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locations.delete(location!.id)
        .then(_data => {
          disclosure.onClose();
          navigate("/dashboard/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  return (
    <ModalBuilder
      title="Редактирование локации"
      fields={editLocationFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: location?.display_name,
        address: location?.address,
        city: location?.city,
        metro_station: location?.metro_station,
        district: location?.district,
      }} />
  );
}