import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";
import { api } from "../../../api";
import { Skeleton } from "@nextui-org/react";

interface Breadcrumb {
  displayName: string;
  href: string;
}

const routes = [
  { path: "/dashboard", displayName: "Панель управления" },
  { path: "/dashboard/selectProfile", displayName: "Выбрать профиль" },
  { path: "/dashboard/location/:id", displayName: "Локация #:id" },
  { path: "/dashboard/location/:id/office/:officeId", displayName: "Офис #:officeId" },
  { path: "/dashboard/location/:id/view", displayName: "Просмотр Локации #:id" },
  { path: "/dashboard/locks", displayName: "Замки" },
  { path: "/dashboard/locks/:id", displayName: "Замок #:id" },
  { path: "/dashboard/locks/:id/view", displayName: "Просмотр Замка #:id" },
  { path: "/dashboard/tickets", displayName: "Тикеты" },
  { path: "/dashboard/tickets/:id", displayName: "Тикет #:id" },
  { path: "/dashboard/renters", displayName: "Арендаторы" },
  { path: "/dashboard/renters/:id", displayName: "Просмотр Арендатора #:id" },
  { path: "/dashboard/system", displayName: "Системные параметры" },
  { path: "/dashboard/officeTypes", displayName: "Типы офисов" },
  { path: "/dashboard/userRoles", displayName: "Роли пользователей" },
  { path: "/dashboard/lockSystems", displayName: "Системы замков" },
  { path: "/dashboard/users", displayName: "Сотрудники" },
  { path: "/dashboard/users/:id/view", displayName: "Просмотр пользователя #:id" },
  { path: "/dashboard/payments", displayName: "Платежи" },
  { path: "/dashboard/payments/:id/view", displayName: "Просмотр платежа #:id" },
  { path: "/dashboard/rentedOffices", displayName: "Арендованные офисы" },
  { path: "/dashboard/rentedOffices/:id/view", displayName: "Просмотр арендованного офиса #:id" },
  { path: "/dashboard/offices", displayName: "Офисы" },
  { path: "/dashboard/offices/:id/view", displayName: "Просмотр офиса #:id" },
  { path: "/dashboard/locations", displayName: "Локации" },
  { path: "/dashboard/locations/:id/view", displayName: "Просмотр Локации #:id" },
];

function normalizePath(path: string) {
  return path.replace(/\/+$/, "");
}

async function fetchDisplayName(entityType: string, id: string): Promise<string> {
  return new Promise<string>((resolve) => {
    switch (entityType) {
      case 'location':
      case 'locations':
        api.locations.get(Number(id))
          .then(res => {
            resolve(`Локация «${res.data.display_name}»`);
          })
          .catch(_err => {
            resolve("Локация");
          })
        break;
      case 'offices':
      case 'office':
        api.offices.get(Number(id))
          .then(res => {
            resolve(`Офис «${res.data.display_name}»`);
          })
          .catch(_err => {
            resolve("Офис");
          })
        break;
      case 'locks':
        api.locks.get(Number(id))
          .then(res => {
            resolve(`Замок «${res.data.display_name}»`);
          })
          .catch(_err => {
            resolve("Замок");
          })
        break;
      case 'renters':
        api.renters.get(Number(id))
          .then(res => {
            resolve(`Компания «${res.data.display_name}»`);
          })
          .catch(_err => {
            resolve("Компания");
          })
        break;
      case 'users':
        api.users.get(Number(id))
          .then(res => {
            resolve(`${res.data.first_name} ${res.data.last_name}`);
          })
          .catch(_err => {
            resolve("Пользователь");
          })
        break;
      case 'payments':
        api.billings.get(Number(id))
          .then(res => {
            resolve(`${res.data.description}`);
          })
          .catch(_err => {
            resolve("Платёж");
          })
        break;
      case 'tickets':
        api.tickets.get(Number(id))
          .then(res => {
            resolve(`Обращение «${res.data.title}»`);
          })
          .catch(_err => {
            resolve("Компания");
          })
        break;
    }
  });
}

function getBreadcrumbs(pathname: string, setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>) {
  const normalizedPathname = normalizePath(pathname);
  const pathParts = normalizedPathname.split('/').filter(Boolean);
  const breadcrumbs: Breadcrumb[] = [];

  let currentPath = '';
  pathParts.forEach((part, index) => {
    currentPath += `/${part}`;
    const route = routes.find(route => {
      const routeParts = route.path.split('/').filter(Boolean);
      if (routeParts.length !== pathParts.slice(0, index + 1).length) {
        return false;
      }
      return routeParts.every((rp, i) => rp.startsWith(':') || rp === pathParts[i]);
    });

    if (route) {
      let displayName = route.displayName;
      const routeParts = route.path.split('/').filter(Boolean);

      routeParts.forEach((rp, i) => {
        if (rp.startsWith(':')) {
          const entityType = routeParts[i - 1];
          const entityId = pathParts[i];
          displayName = Math.random().toString();

          fetchDisplayName(entityType, entityId)
            .then(newDisplayName => {
              setBreadcrumbs(breadcrumbs => {
                const nB = [...breadcrumbs];
                const b = breadcrumbs.find(b => b.displayName == displayName);

                if (b) {
                  const bi = breadcrumbs.indexOf(b);
                  nB[bi] = { displayName: newDisplayName, href: b.href || "" };
                }

                return nB;
              });
            });
        }
      });

      breadcrumbs.push({ displayName, href: normalizePath(currentPath) });
    }
  });

  return breadcrumbs;
}

export function Breadcrumbs({ path }: { path: string }) {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const location = useLocation();

  useEffect(() => {
    setBreadcrumbs(getBreadcrumbs(path, setBreadcrumbs));
  }, [path]);

  const normalizedLocationPath = normalizePath(location.pathname);

  return (
    <nav className="border-2 border-solid border-zinc-300 dark:border-zinc-800 px-3 py-1 rounded-lg flex flex-row gap-2 items-center">
      {breadcrumbs.map((breadcrumb, index) => (
        <>
          {breadcrumb ? (
            <>
              {breadcrumb.displayName.startsWith("0.") ? (
                <span
                  className={`text-sm flex flex-row gap-2 items-center`}
                  key={index}
                >
                  <Skeleton className="rounded">{breadcrumb.displayName}</Skeleton>
                  {index < breadcrumbs.length - 1 && <FaAngleRight className="text-foreground-500" />}
                </span>
              ) : (
                <span
                  className={`text-sm flex flex-row gap-2 items-center ${normalizePath(breadcrumb.href) === normalizedLocationPath ? 'text-foreground font-medium' : ''
                    }`}
                  key={index}
                >
                  <Link to={breadcrumb.href}>{breadcrumb.displayName}</Link>
                  {index < breadcrumbs.length - 1 && <FaAngleRight className="text-foreground-500" />}
                </span>
              )}
            </>
          ) : null}
        </>
      ))}
    </nav>
  );
}