import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";
import { Button, Input, Link } from "@nextui-org/react";

interface PasswordRecoveryPromptForm {
  login: string;
}

export function PasswordRecoveryPrompt() {
  const {
    register,
    // handleSubmit,
    formState: { errors },
  } = useForm<PasswordRecoveryPromptForm>({
    defaultValues: {},
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-col items-center justify-center w-full h-screen bg-[url(/Bg.png)] bg-cover bg-center">
        <motion.div
          className="bg-white rounded-xl p-8 shadow flex flex-col gap-2 md:min-w-[515px]"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <h2>Восстановление пароля</h2>
          <Input
            label="Имя пользователя"
            placeholder="Введите логин"
            variant="bordered"
            type="text"
            {...register("login", { required: true })}
            className="mb-2"
            isInvalid={!!errors["login"]}
            errorMessage={errors["login"]?.message?.toString()}
          />
          <span className="max-w-96">Если к имени пользователя, указанному сверху, была привязана электронная почта, мы отправим Вам письмо с ссылкой на восстановление аккаунта.</span>
          <div className="mt-4 flex gap-4">
            <Button color="primary" className="w-full font-semibold" type="submit" onClick={() => toast.success("Ссылка на восстановление успешно отправлена!")}>
              Восстановить
            </Button>
          </div>
          <div className="mt-4 flex w-full flex-col items-center">
            <Link href="/" className="font-medium" showAnchorIcon>Я вспомнил пароль</Link>
          </div>
        </motion.div>
        {process.env.VITE_BUILD_BRANCH?.startsWith("dev") && (
          <motion.div
            className="flex flex-col items-center gap-2 mt-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <span className="text-sm text-zinc-600 font-medium">
              <a href={`https://github.com/Elasticwork-space/ElasticAdmin/commit/${process.env.VITE_COMMIT_HASH}`}>
                {process.env.VITE_COMMIT_HASH} / {process.env.VITE_BUILD_BRANCH}
              </a>
            </span>
          </motion.div>
        )}
        <br />
      </div>
    </>
  );
}
