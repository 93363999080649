import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { getIdFromLocationHandbook, getIdFromLockSystemHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface ICreateLockForm {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location?: number;
}

const createLockFields: InputField[] = [
  {
    label: "Название замка",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Система",
    name: "lock_system",
    placeholder: "",
    type: "lock-system",
    isRequired: true
  }, {
    label: "Локация",
    name: "location",
    placeholder: "",
    type: "location",
    isRequired: false
  }, {
    label: "Сервисный ID",
    name: "lock_service_id",
    placeholder: "1",
    type: "text",
    isRequired: true
  }
]

export function CreateLockModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: ICreateLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const lockSystem = await getIdFromLockSystemHandbook(String(data.lock_system));
      const location = await getIdFromLocationHandbook(String(data.location));
      
      api.locks.create({
        display_name: data.display_name,
        lock_system: Number(lockSystem),
        lock_service_id: data.lock_service_id,
        location: {
          id: Number(location)
        }
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder title="Создание замка" modalErrors={modalErrors} fields={createLockFields} isOpen={isOpen} isSensitiveData={false} onOpenChange={onOpenChange} onSubmit={onSubmit} />
  );
}