import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { EditLockSystemModal } from "../../components/modals/lock/editLockSystemModal";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil } from "react-icons/fa6";
import { api } from "../../../api";
import { LockSystem } from "../../../api/lockSystems/lockSystems";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { ColumnType } from "../../components/tables/tableTypes";

export function LockSystemsPage() {
  const [lockSystems, setLockSystems] = useState<LockSystem[] | null>(null);
  const [lockSystem, setLockSystem] = useState<LockSystem | null>(null);

  const handleError = useErrorHandling();

  const editLockSystemModalDisclosure = useDisclosure();
  const fullscreenDisclosure = useDisclosure();

  const editLockSystem = (lockSystem: LockSystem) => {
    setLockSystem(lockSystem);
    editLockSystemModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.lockSystems.list()
      .then(response => {
        setLockSystems(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    editLockSystemModalDisclosure.isOpen
  ]);

  return (
    <>
      <EditLockSystemModal disclosure={editLockSystemModalDisclosure} lockSystem={lockSystem} />
      <div className="p-4">
        <div className="flex flex-col gap-2 mb-2">
          <div className="flex flex-row gap-4 items-center mb-4">
            <span className="font-bold text-2xl">Системы замков</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <TableBuilder
            fullscreenDisclosure={fullscreenDisclosure}
            columns={[
              {
                label: "Название системы",
                key: "display_name",
                type: ColumnType.String,
                sortable: true
              },
              {
                key: "actions",
                label: "Действия",
                type: ColumnType.Actions,
                actions: [
                  {
                    icon: <FaPencil />,
                    onClick: (_value, row) => { editLockSystem(row) }
                  }
                ]
              }              
            ]}
            data={lockSystems || []}
            rowsPerPage={10}
            defaultSortDescriptor={{
              column: "display_name",
              direction: "ascending"
            }}
          />
        </div>
      </div>
    </>
  );
}
