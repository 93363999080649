export async function getIdFromOfficeTypeHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromOfficeHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromRenterHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromUserHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromLockHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromLockSystemHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromUserRoleHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromLocationHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromCategoryHandbook(displayName: string) {
  return displayName;
}

export async function getIdFromRequisitesHandbook(displayName: string) {
  return displayName;
}