import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { Office } from "../../../../api/offices";
import { getIdFromLocationHandbook, getIdFromLockHandbook, getIdFromOfficeTypeHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IEditOfficeForm {
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  floor: number;
  office_lock: number;
  location: number;
  commissioning_date: string;
}

const editOfficeFields: InputField[] = [
  {
    label: "Название офиса",
    name: "display_name",
    placeholder: "Офис 1",
    type: "text",
    isRequired: true
  }, {
    label: "Тип офиса",
    name: "office_type",
    placeholder: "",
    type: "office-type",
    isRequired: true
  }, {
    label: "Площадь",
    name: "area",
    placeholder: "10м",
    type: "text",
    isRequired: true
  }, {
    label: "Стоимость",
    name: "price",
    placeholder: "90000",
    type: "text",
    isRequired: true
  }, {
    label: "Количество комнат",
    name: "room_count",
    placeholder: "2",
    type: "text",
    isRequired: true
  }, {
    label: "Этаж",
    name: "floor",
    placeholder: "5",
    type: "text",
    isRequired: true
  }, {
    label: "Замок",
    name: "office_lock",
    placeholder: "",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location",
    placeholder: "",
    type: "location",
    isRequired: true
  }, {
    label: "Дата ввода в эксплуатацию",
    name: "commissioning_date",
    placeholder: "",
    type: "datetime-local",
    isRequired: false
  }
]

export function EditOfficeModal({ disclosure, office }: { disclosure: ReturnType<typeof useDisclosure>, office: Office | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IEditOfficeForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const officeType = await getIdFromOfficeTypeHandbook(String(data.office_type));
      const lock = await getIdFromLockHandbook(String(data.office_lock));
      const location = await getIdFromLocationHandbook(String(data.location));

      api.offices.update(office!.id, {
        display_name: data.display_name,
        office_type: Number(officeType),
        area: data.area,
        floor: data.floor,
        office_lock: Number(lock),
        price: data.price,
        room_count: data.room_count,
        location: Number(location),
        commissioning_date: data.commissioning_date
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.offices.delete(office!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование офиса"
      fields={editOfficeFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: office?.display_name,
        office_type: office?.office_type,
        area: office?.area,
        price: office?.price,
        room_count: office?.room_count,
        floor: office?.floor,
        office_lock: office?.office_lock,
        location: office?.location,
        commissioning_date: office?.commissioning_date
      }} />
  );
}