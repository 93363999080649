import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface User {
  id: number;
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_number: string;
  role: number;
  renter: number;
  telegram?: number;
}

export interface UserCreate {
  username: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  phone_number: string;
  role: number;
  renter: number;
  password?: string;
  telegram?: number;
}

export interface UserUpdate {
  username?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  role?: number;
  renter?: number;
  password?: string;
  telegram?: number;
}

export function UsersAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/users/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<User[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/users/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<User>;
    },
    create: async (userData: UserCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/users/?business_profile_id=${businessProfileId}`, userData, true) as ApiResponse<User>;
    },
    update: async (id: number, userData: UserUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/users/${id}?business_profile_id=${businessProfileId}`, userData, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/users/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    }
  }
}
