import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../../components/modals/modalBuilder";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { Billing } from "../../../api/billing";
import { getIdFromRenterHandbook } from "../../../utils/getIdFromHandbooks";
import { Column, ColumnType } from "../tables/tableTypes";

interface IBillingEditForm {
  number: number;
  date: string;
  payer: number;
  provider: number;
  amount: number;
  paid: boolean;
  description: string;
}
const editBillingFields: InputField[] = [
  { label: "Номер", name: "number", placeholder: "Номер", type: "text", isRequired: true },
  { label: "Дата", name: "date", placeholder: "Дата", type: "datetime-local", isRequired: true },
  { label: "Плательщик", name: "payer", placeholder: "Плательщик", type: "renter", isRequired: true },
  { label: "Поставщик", name: "provider", placeholder: "Поставщик", type: "text", isRequired: true },
  { label: "Сумма", name: "amount", placeholder: "Сумма", type: "text", isRequired: true },
  { label: "Оплачено", name: "paid", placeholder: "Оплачено", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: true },
];

const viewItemColumns: Column[] = [
  {
    label: "Номер",
    key: "number",
    type: ColumnType.Number
  },
  {
    label: "Дата",
    key: "date",
    type: ColumnType.Date
  },
  {
    label: "Плательщик",
    key: "payer",
    type: ColumnType.Renter
  },
  {
    label: "Поставщик",
    key: "provider",
    type: ColumnType.String
  },
  {
    label: "Сумма",
    key: "amount",
    type: ColumnType.Number
  },
  {
    label: "Оплачено",
    key: "paid",
    type: ColumnType.Boolean
  },
  {
    label: "Описание",
    key: "description",
    type: ColumnType.String
  }
];

export function ViewBillingPage() {
  const { id } = useParams();
  const [billing, setBilling] = useState<Billing | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.billings.get(Number(id))
      .then(response => {
        setBilling(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных платежа!");
      });
  }, [id]);

  const onSubmit = async (data: IBillingEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const payer = await getIdFromRenterHandbook(String(data.payer));

      api.billings.update(billing!.id, {
          number: data.number,
          date: data.date,
          payer: Number(payer),
          provider: data.provider,
          amount: data.amount,
          paid: data.paid,
          description: data.description,
          discount: 0,
          period: "Январь",
          office: 0
        })
        .then(_data => {
          toast.success("Платёж успешно обновлён!");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при редактировании биллинга!");
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.billings.delete(billing!.id)
        .then(_data => {
          navigate("/dashboards/payments")
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при удалении биллинга!");
          resolve(false);
        });
    });
  };

  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Биллинг"
          fields={editBillingFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={billing} columns={viewItemColumns} />}
          defaultValues={{
            number: billing?.number,
            date: billing?.date,
            payer: billing?.payer,
            provider: billing?.provider,
            amount: billing?.amount,
            paid: billing?.paid,
            description: billing?.description
          }} />
      )}
    </>
  );
}
