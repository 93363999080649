import { Button, useDisclosure } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { BusinessProfile } from "../../../api/businessProfiles";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { FaMapLocationDot, FaPlus } from "react-icons/fa6";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { setBusinessProfile } from "../../store/businessProfile/reducer";
import { CreateBusinessProfileModal } from "../../components/modals/businessProfile/createBusinessProfileModal";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { sortAlphabeticallyByKey } from "../../../utils";

export function SelectProfile() {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [profiles, setProfiles] = useState<BusinessProfile[] | null>(null);

  const createBusinessProfileModalDisclosure = useDisclosure();

  const handleError = useErrorHandling();

  useEffect(() => {
    api.businessProfiles.list()
      .then(response => {
        const sortedData = sortAlphabeticallyByKey(response.data, "display_name");
        setProfiles(sortedData);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [setProfiles, createBusinessProfileModalDisclosure.isOpen]);

  // const selectProfile = useCallback(() => {
  //   localStorage.setItem('profile', 'Сокол');
  //   navigate('/dashboard');
  // }, []);

  const selectProfile = useCallback((profile: BusinessProfile) => {
    localStorage.setItem("businessProfileId", profile.id.toString());
    localStorage.setItem("businessProfileCachedDisplayName", profile.display_name);
    dispatch(setBusinessProfile({ id: profile.id, display_name: profile.display_name }));
    // navigate("/dashboard/"); << навбар не обновляется, приходится использовать location.href
    location.href = "/dashboard/";
  }, []);

  return (
    <>
      <CreateBusinessProfileModal disclosure={createBusinessProfileModalDisclosure} />

      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <span className="text-2xl font-bold">Выбор бизнес-профиля</span>
          <Button size="md" color="primary" startContent={<FaPlus />} className="max-w-fit" onClick={() => createBusinessProfileModalDisclosure.onOpen()}>Создать бизнес-профиль</Button>
        </div>
        <span className="text-sm max-w-96">
          Вам необходимо выбрать желаемый бизнес-профиль. Ваш выбор будет сохранён в рамках этого браузера и Вы сможете в любой момент выйти из выбранного ранее профиля и зайти в другой.
        </span>
        <span className="text-sm max-w-96 font-medium">
          Ваши бизнес-профили:
        </span>
        <div className="flex flex-row flex-wrap gap-2 max-w-[1000px] mt-2">
          {/* <div className="flex flex-col bg-background rounded-xl" onClick={selectProfile}>
            <div className="block w-64 h-32 bg-gray-500 bg-opacity-50 rounded-t-xl" />
            <div className="flex flex-col gap-3 p-3">
              <span className="text-sm font-bold">ElasticWork Сокол</span>
              <span className="text-sm">Профиль ООО "ммм)"</span>
            </div>
          </div>
           */}
          {profiles && profiles.length > 0 && (
            <>
              {profiles.map((profile, index) => (
                <div className="flex flex-col bg-background border-2 border-default-200 rounded-xl cursor-pointer select-none" onClick={() => selectProfile(profile)} key={index}>
                  <div className="flex flex-col gap-3 p-4">
                    <span className="text-medium font-bold">{profile.display_name}</span>
                    <div className="flex flex-row gap-2 items-center">
                      <FaMapLocationDot />
                      <span className="text-sm font-medium">{profile.legal_address}</span>
                    </div>
                    {/* <div className="flex flex-row gap-2 items-center">
                      <FaFile />
                      <span className="text-sm font-medium">ИНН { profile.inn }</span>
                      <span className="text-sm font-medium">ОГРН { profile.ogrn }</span>
                    </div> */}
                  </div>
                </div>
              ))}
            </>
          )}
          {!profiles || profiles.length == 0 && (
            <span>Похоже, у вас нет доступных бизнес-профилей.</span>
          )}
        </div>
      </div>
    </>
  )
}