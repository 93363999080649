import { Button } from "@nextui-org/react";
import { useRouteError } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import { HiRefresh, HiArrowLeft, HiClipboard } from "react-icons/hi";
import { useEffect, useState } from "react";

const getErrorTitle = (e: any) => {
  switch (e.status) {
    case 400: return "Неверный запрос";
    case 401: return "Не авторизован";
    case 403: return "Доступ запрещен";
    case 404: return "Страница не найдена";
    case 500: return "Внутренняя ошибка сервера";
    default: return "Неизвестная ошибка";
  }
};

const getErrorDescription = (e: any) => {
  switch (e.status) {
    case 400: return "Проверьте правильность введенных данных и попробуйте снова.";
    case 401: return "Пожалуйста, авторизуйтесь, чтобы продолжить.";
    case 403: return "У вас нет прав для доступа к этому ресурсу.";
    case 404: return "Кажется, страница, которую вы ищете, не существует.";
    case 500: return "Кажется, произошла ошибка на сервере. Пожалуйста, попробуйте позже.";
    default: return "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже.";
  }
};

export function ErrorBoundaryPage() {
  const darkMode = useDarkMode(false);
  const error: any = useRouteError();
  const [copied, setCopied] = useState(false);
  const [textError, setTextError] = useState("");

  useEffect(() => {
    if(JSON.stringify(error).length < 3) {
      setTextError(error.toString() + "\n" + error.stack ? error.stack.toString() : '');
    } else {
      setTextError(JSON.stringify(error, null, 2));
    }
  }, [error]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div
      id="main"
      className={`${darkMode.value ? "dark bg-black" : "bg-zinc-100"} text-foreground w-full h-screen flex flex-col gap-6 items-center p-4`}
    >
      <div className="flex flex-row gap-4 flex-wrap items-center">
        <img src="https://media1.tenor.com/m/Om3_U2YnLZIAAAAd/komaru-komaru-everyday.gif" width="160" alt="котята" className="rounded-lg shadow-lg" />
        <div className="flex flex-col gap-4">
          <h2 className="text-3xl font-semibold">{getErrorTitle(error)}</h2>
          <span className="text-center text-lg">{getErrorDescription(error)}</span>
        </div>
      </div>

      <div className="w-full max-w-3xl bg-zinc-50 p-6 rounded-xl border border-zinc-200 dark:bg-zinc-900 dark:border-zinc-800 relative">
        <h3 className="text-xl font-semibold mb-2">Подробности ошибки</h3>

        <button
          onClick={() => handleCopy(textError)}
          className="absolute top-4 right-4 p-2 bg-zinc-200 rounded-full hover:bg-zinc-300 dark:bg-zinc-700 dark:hover:bg-zinc-600 transition-colors"
          title="Скопировать текст ошибки"
        >
          <HiClipboard className="w-5 h-5 text-zinc-700 dark:text-zinc-200" />
        </button>

        <pre className="bg-zinc-100 p-4 rounded-md overflow-x-auto dark:bg-zinc-800">
          <code>{textError}</code>
        </pre>
        {error?.error?.stack && (
          <>
            <h4 className="mt-4 text-lg font-semibold">Stack Trace</h4>
            <pre className="bg-zinc-100 p-4 rounded-md overflow-x-auto dark:bg-zinc-800">
              <code>{error.error.stack}</code>
            </pre>
          </>
        )}
        <p className="text-sm text-foreground-500 mt-4">
          Вы можете скопировать текст ошибки и отправить его техническому администратору для решения проблемы.
        </p>
        {copied && (
          <p className="text-sm text-green-500 mt-2">Текст ошибки скопирован!</p>
        )}
      </div>

      <div className="flex flex-row gap-4 mt-6 flex-wrap items-center justify-center">
        <Button as="a" href="/" color="success" variant="flat">
          Вернуться на главную
        </Button>
        <Button color="default" variant="bordered" startContent={<HiArrowLeft />} onClick={() => window.history.back()}>
          Назад
        </Button>
        <Button color="default" variant="bordered" startContent={<HiRefresh />} onClick={() => window.location.reload()}>
          Обновить
        </Button>
      </div>
    </div>
  );
}
