import { Button, useDisclosure, Link } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { api } from "../../../../../api";
import { Lock, LockPasscode } from "../../../../../api/locks";
import { toast } from "react-toastify";
import { TableBuilder } from "../../../../components/tables/tableBuilder";
import { FaExpand, FaLockOpen, FaPencil, FaPlus } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { CreatePasscodeModal } from "../../../../components/modals/lock/createPasscodeModal";
import { EditPasscodeModal } from "../../../../components/modals/lock/editPasscodeModal";
import useErrorHandling from "../../../../../hooks/useErrorHandling";
import { ColumnType } from "../../../../components/tables/tableTypes";

export function LockPasscodesPage() {
  const { id } = useParams() as { id: string };
  const [lock, setLock] = useState<Lock | null>(null);
  const [passcodes, setPasscodes] = useState<LockPasscode[] | null>(null);
  const [passcode, setPasscode] = useState<LockPasscode | null>(null);

  const handleError = useErrorHandling();

  const createPasscodeModalDisclosure = useDisclosure();
  const editPasscodeModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editPasscode = (passcode: LockPasscode) => {
    setPasscode(passcode);
    editPasscodeModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.locks.get(Number(id))
      .then(response => {
        setLock(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });

    api.locks.listPasscodes(Number(id))
      .then(response => {
        setPasscodes(response.data)
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createPasscodeModalDisclosure.isOpen,
    editPasscodeModalDisclosure.isOpen
  ])

  return (
    <>
      <CreatePasscodeModal disclosure={createPasscodeModalDisclosure} lock={lock} />
      <EditPasscodeModal disclosure={editPasscodeModalDisclosure} lock={lock} passcode={passcode} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link onClick={_e => { api.locks.unlock(Number(id)).then(_res => toast.success("Замок успешно открыт!")) }} className="flex flex-row gap-2 items-center p-4 rounded-xl font-medium text-foreground-600 cursor-pointer">
            <FaLockOpen />
            Открыть замок
          </Link>
          <Link href={`/dashboard/locks/${id}`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Журнал действий
          </Link>
          <Link href={`/dashboard/locks/${id}/passcodes`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Пароли
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row justify-between w-full gap-4 items-center">
                <div className="flex flex-row items-center gap-2">
                  <span className="font-medium">
                    Замок {lock?.display_name}
                  </span>
                  <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
                </div>
                <Button startContent={<FaPlus />} size="sm" color="primary" variant="solid" className="max-w-fit" onClick={() => createPasscodeModalDisclosure.onOpen()}>Создать пароль</Button>
              </div>
            </div>
            <TableBuilder
              fullscreenDisclosure={fullscreenDisclosure}
              removeWrapper
              displayOptions={false}
              columns={[
                {
                  label: "Пароль",
                  key: "passcode",
                  type: ColumnType.String
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editPasscode(row) }
                    }
                  ]
                }
              ]}
              data={passcodes || []}
              rowsPerPage={10}
            />
          </div>
        </div>
      </div>
    </>
  );
}