import { Button, Skeleton } from "@nextui-org/react";
import { useAppSelector } from "../../../hooks/useAppSelector";

export function MyProfilePage() {
  const user = useAppSelector(state => state.user.currentUser);
  const bussinessProfile = useAppSelector(state => state.businessProfile.businessProfile);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full p-4">
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <span className="font-medium">Мой профиль</span>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <Button
                  color="primary"
                  variant="solid"
                  size="sm"
                  className="max-w-fit"
                >
                  Редактировать
                </Button>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <Skeleton className="w-16 h-16 rounded-full" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium">{user.first_name} {user.last_name}</span>
                <span className="text-foreground-600">Администратор в {bussinessProfile.display_name}</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Номер телефона</span>
                <span className="font-medium">{user.phone_number}</span>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Адрес электронной почты</span>
                <span className="font-medium">{user.email}</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center mb-3">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">О себе</span>
                <span className="font-medium">Пока что тут будет пусто :)</span>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row gap-4 items-center">
                <span className="font-medium">Связи</span>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-full items-center">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-foreground-600">Администратор</span>
                <span className="font-medium">{bussinessProfile.display_name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
