import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { api } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BusinessProfile } from "../../../../api/businessProfiles";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

const editBusinessProfileFields: InputField[] = [
  {
    label: "Название профиля",
    name: "display_name",
    placeholder: "ElasticWork.Space",
    type: "text",
    isRequired: true
  }, {
    label: "Адрес",
    name: "legal_address",
    placeholder: "г. Москва, ул...",
    type: "text",
    isRequired: true
  }, {
    label: "ИНН",
    name: "inn",
    placeholder: "",
    type: "text",
    isRequired: true
  }, {
    label: "ОГРН",
    name: "ogrn",
    placeholder: "",
    type: "text",
    isRequired: true
  }
]

export function EditBusinessProfileModal({ disclosure, businessProfile }: { disclosure: ReturnType<typeof useDisclosure>, businessProfile: BusinessProfile | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: { display_name: string, legal_address: string, inn: string, ogrn: string }) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.businessProfiles.update(businessProfile!.id, {
        display_name: data.display_name,
        legal_address: data.legal_address,
        inn: data.inn,
        ogrn: data.ogrn
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.businessProfiles.delete(businessProfile!.id)
        .then(_data => {
          localStorage.removeItem("businessProfileId"); // на всякий случай
          disclosure.onClose();
          navigate("/dashboard/selectProfile");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Изменение бизнес-профиля"
      fields={editBusinessProfileFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      isSensitiveData={true}
      onDelete={onDelete}
      submitButtonText="Сохранить"
      defaultValues={{
        display_name: businessProfile?.display_name,
        legal_address: businessProfile?.legal_address,
        inn: businessProfile?.inn,
        ogrn: businessProfile?.ogrn
      }} />
  );
}