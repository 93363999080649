import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, useDisclosure } from "@nextui-org/react";
import { getPortal } from "../../../../utils";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { Renter } from "../../../../api/renters";
import { api } from "../../../../api";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { toast } from "react-toastify";
import { getIdFromOfficeHandbook } from "../../../../utils/getIdFromHandbooks";
import { OfficeHandbook } from "../../handbooks/officeHandbook";

interface IRentedOfficeCreateForm {
  office: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
  deposit: number;
}

export function CreateRentedOfficeByOgrnModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const { isOpen, onOpenChange } = disclosure;
  const [stage, setStage] = useState<"ogrn" | "creating">("ogrn");
  const [renter, setRenter] = useState<Renter | null>(null);
  const [loadingOgrn, setLoadingOgrn] = useState<boolean>(false);
  const [loadingOffice, setLoadingOffice] = useState<boolean>(false);

  const handleError = useErrorHandling();

  useEffect(() => {
    setStage("ogrn");
    setRenter(null);
    setLoadingOgrn(false);
    setLoadingOffice(false);
  }, []);
  
  const ogrnForm = useForm();
  const rentedOfficeForm = useForm<IRentedOfficeCreateForm>();

  const handleOgrn = useCallback((data: any) => {
    setLoadingOgrn(true);

    api.renters.getByOgrn(data.ogrn)
      .then(data => {
        setRenter(data.data);
        setStage("creating");
        setLoadingOgrn(false);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoadingOgrn(false);
      });
  }, [setRenter]);

  const handleRentedOffice = useCallback(async (data: IRentedOfficeCreateForm) => {
    setLoadingOffice(true);
    const office = await getIdFromOfficeHandbook(String(data.office));

    api.rentedOffices.create({
      office: Number(office),
      renter: Number(renter?.id),
      price: data.price,
      description: data.description,
      start_date: data.start_date,
      end_date: data.end_date,  
      deposit: data.deposit
    })
      .then(_data => {
        disclosure.onClose();
        setLoadingOffice(false);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        setLoadingOffice(false);
      });
  }, [renter]);

  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} portalContainer={getPortal()} backdrop="blur">
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>
              <span className="font-medium">Поиск компании по ОГРН/ОГРНИП</span>
            </ModalHeader>
            <ModalBody>
              {stage == "ogrn" ? (
                <>
                  <form onSubmit={ogrnForm.handleSubmit(handleOgrn)}>
                    <div className="flex flex-row items-center gap-1">
                      <Input
                        {...ogrnForm.register("ogrn", { required: true })}
                        isInvalid={!!ogrnForm.formState.errors["ogrn"]?.message}
                        errorMessage={ogrnForm.formState.errors["ogrn"]?.message?.toString() || ""}
                        label="ОГРН/ОГРНИП"
                        placeholder="Введите ОГРН/ОГРНИП"
                        variant="bordered"
                      />
                      <Button
                        type="submit"
                        variant="flat"
                        startContent={<FaMagnifyingGlass />}
                        size="lg"
                        className="h-14 rounded-xl"
                        isLoading={loadingOgrn}
                      />
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <form onSubmit={rentedOfficeForm.handleSubmit(handleRentedOffice)}>
                    <div className="flex flex-col">
                      <div className="flex flex-col p-4 border-2 rounded-xl border-foreground-200 dark:border-foreground-100 mb-4">
                        <div className='flex flex-col'>
                          <span className="font-semibold text-sm text-default-500">Название компании:</span>
                          <span className="font-semibold text-sm mb-2">{ renter?.display_name }</span>
                        </div>
                        <div className='flex flex-col'>
                          <span className="font-semibold text-sm text-default-500">ОГРН:</span>
                          <span className="font-semibold text-sm">{ renter?.orgn }</span>
                        </div>
                      </div>
                      <div className="mb-4 block">
                        <OfficeHandbook
                          register={rentedOfficeForm.register("office", { required: true })}
                          isInvalid={!!rentedOfficeForm.formState.errors["office"]}
                          errorMessage={rentedOfficeForm.formState.errors["office"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="mb-4 block">
                        <Input
                          {...rentedOfficeForm.register("price", { required: true })}
                          type="text"
                          label="Цена"
                          placeholder="Цена"
                          variant="bordered"
                          isInvalid={!!rentedOfficeForm.formState.errors["price"]}
                          errorMessage={rentedOfficeForm.formState.errors["price"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="mb-4 block">
                        <Input
                          {...rentedOfficeForm.register("description", { required: true })}
                          type="text"
                          label="Описание"
                          placeholder="Описание"
                          variant="bordered"
                          isInvalid={!!rentedOfficeForm.formState.errors["description"]}
                          errorMessage={rentedOfficeForm.formState.errors["description"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="mb-4 block">
                        <Input
                          {...rentedOfficeForm.register("start_date", { required: true })}
                          type="datetime-local"
                          label="Дата начала"
                          placeholder="Дата начала"
                          variant="bordered"
                          isInvalid={!!rentedOfficeForm.formState.errors["start_date"]}
                          errorMessage={rentedOfficeForm.formState.errors["start_date"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="mb-4 block">
                        <Input
                          {...rentedOfficeForm.register("end_date", { required: true })}
                          type="datetime-local"
                          label="Дата окончания"
                          placeholder="Дата окончания"
                          variant="bordered"
                          isInvalid={!!rentedOfficeForm.formState.errors["end_date"]}
                          errorMessage={rentedOfficeForm.formState.errors["end_date"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="mb-4 block">
                        <Input
                          {...rentedOfficeForm.register("deposit", { required: true })}
                          type="text"
                          label="Депозит"
                          placeholder="Депозит"
                          variant="bordered"
                          isInvalid={!!rentedOfficeForm.formState.errors["deposit"]}
                          errorMessage={rentedOfficeForm.formState.errors["deposit"]?.message?.toString() || ""}
                        />
                      </div>
                      <div className="flex flex-row gap-2">
                        <div className="flex flex-grow flex-row gap-2">
                          <Button color="default" onClick={onClose} variant="flat">Закрыть</Button>
                        </div>
                        <Button color="success" type="submit" variant="flat" isLoading={loadingOffice}>Создать</Button>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </ModalBody>
            <ModalFooter>

            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
