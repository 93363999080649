import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Lock {
  id: number;
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location: {
    id: number;
    display_name: string;
  } | null;
}

export interface LockCreate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  bussiness_profile_id: number;
  location: {
    id: number,
    display_name?: string
  }
}

export interface LockUpdate {
  display_name: string;
  lock_system: number;
  lock_service_id: number;
  location: {
    id: number,
    display_name?: string
  }
}

export interface LockPasscode {
  id: number;
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeCreate {
  passcode: string;
  lock_id: number;
}

export interface LockPasscodeUpdate {
  passcode: string;
}

export interface LockLog {
  id: number;
  lock_id: number;
  user_id: number;
  CREATED_AT: string;
  message: string;
}

export type NeveroyatniyKostyl = [LockLog[], number];

export function LocksAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Lock>;
    },
    unlock: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/locks/${id}/unlock?business_profile_id=${businessProfileId}`, {}, true) as ApiResponse<Lock>;
    },
    create: async (lockData: any) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      const params = {...lockData};
      params.business_profile_id = businessProfileId;

      return await crud.post(`${baseUrl}/admin/locks/?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<Lock>;
    },
    update: async (id: number, lockData: any) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      const params = {...lockData};
      params.business_profile_id = businessProfileId;

      return await crud.put(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, params, true) as ApiResponse<{}>;
    },
    delete: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/locks/${id}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    listPasscodes: async (lockId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<LockPasscode[]>;
    },
    createPasscode: async (lockId: number, passcodeData: LockPasscodeCreate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/locks/${lockId}/passcodes?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<LockPasscode>;
    },
    updatePasscode: async (lockId: number, passcodeId: number, passcodeData: LockPasscodeUpdate) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.put(`${baseUrl}/admin/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, passcodeData, true) as ApiResponse<{}>;
    },
    deletePasscode: async (lockId: number, passcodeId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.delete(`${baseUrl}/admin/locks/${lockId}/passcodes/${passcodeId}?business_profile_id=${businessProfileId}`, true) as ApiResponse<{}>;
    },
    getLogs: async (lockId: number, offset: number = 0, limit: number = 10, user_id?: number, time_from?: string, time_to?: string) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/locks/${lockId}/logs?offset=${offset}&limit=${limit}&user_id=${user_id}&time_from=${time_from}&time_to=${time_to}&business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<NeveroyatniyKostyl>;
    }
  }
}
