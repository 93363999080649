import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { api } from "../../../api";
import { Billing } from "../../../api/billing";
import { EditBillModal } from "../modals/bill/editBillModal";

export function PaymentChip({ displayAmount, paymentId }: { displayAmount: string, paymentId: number }) {
  const [item, setItem] = useState<Billing | null>(null);
  const disclosure = useDisclosure();

  useEffect(() => {
    api.billings.get(paymentId)
      .then(res => {
        setItem(res.data);
      })
      .catch(err => {
        console.error("Ошибка при загрузке PaymentChip: " + err);
      });
  }, [paymentId]);

  return (
    <>
      <EditBillModal disclosure={disclosure} billing={item} />
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{ displayAmount}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">{ displayAmount }</Chip>
      )}
    </>
  );
};