import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../../components/loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../../components/modals/modalBuilder";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { OfficeLocation } from "../../../api/locations";
import { Column, ColumnType } from "../tables/tableTypes";

interface ILocationEditForm {
  display_name?: string;
  address?: string;
  city?: string;
  metro_station?: string;
  district?: string;
}

const editLocationFields: InputField[] = [
  {
    label: "Название локации",
    name: "display_name",
    placeholder: "Коворкинг на Мясницкой",
    type: "text",
    isRequired: true
  },
  {
    label: "Адрес",
    name: "address",
    placeholder: "ул Краснобогатырская...",
    type: "text",
    isRequired: true
  },
  {
    label: "Город",
    name: "city",
    placeholder: "Москва",
    type: "text",
    isRequired: true
  },
  {
    label: "Станция метро",
    name: "metro_station",
    placeholder: "Черкизовская",
    type: "text",
    isRequired: true
  },
  {
    label: "Район",
    name: "district",
    placeholder: "Преображенский",
    type: "text",
    isRequired: true
  }
];

const viewLocationColumns: Column[] = [
  {
    label: "Название локации",
    key: "display_name",
    type: ColumnType.String
  },
  {
    label: "Адрес",
    key: "address",
    type: ColumnType.String
  },
  {
    label: "Город",
    key: "city",
    type: ColumnType.String
  },
  {
    label: "Станция метро",
    key: "metro_station",
    type: ColumnType.String
  },
  {
    label: "Район",
    key: "district",
    type: ColumnType.String
  }
];

export function ViewLocationPage() {
  const { id } = useParams();
  const [location, setLocation] = useState<OfficeLocation | null>(null);
  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);
  const navigate = useNavigate();

  useEffect(() => {
    api.locations.get(Number(id))
      .then(response => {
        setLocation(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных локации!");
      });
  }, [id]);

  const onSubmit = (data: ILocationEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locations.update(Number(id), {
        display_name: data.display_name!,
        address: data.address!,
        city: data.city!,
        metro_station: data.metro_station!,
        district: data.district!,
        business_profile: businessProfile.id
      })
        .then(_data => {
          toast.success("Локация успешно отредактирована!");
          navigate("/dashboard/locations/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при редактировании локации!");
          resolve(false);
        });
    });
  };

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.locations.delete(Number(id))
        .then(_data => {
          toast.success("Локация успешно удалена!");
          navigate("/dashboard/locations/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при удалении локации!");
          resolve(false);
        });
    });
  };

  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Локация"
          fields={editLocationFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={location} columns={viewLocationColumns} />}
          defaultValues={{
            display_name: location.display_name,
            address: location.address,
            city: location.city,
            metro_station: location.metro_station,
            district: location.district,
          }}
        />
      )}
    </>
  );
}
