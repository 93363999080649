import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Divider, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { getNormalizedDate } from "../../../utils";
import { Ticket } from '../../../api/tickets/tickets';
import { Office } from '../../../api/offices';
import { User } from '../../../api/users';
import { FaEllipsis, FaPhone } from 'react-icons/fa6';
import { api } from '../../../api';
import { LocationChip } from '../chips/locationChip';

export interface IAttachment {
  name: string;
  url: string;
  type: "image" | "file";
}

export interface IMessage {
  id: number;
  message: string;
  user: string;
  date: string;
  isMe: boolean;
}

export interface IChat {
  id: number;
  title: string;
  ticket: Ticket | null;
  messages: IMessage[];
  adminView: boolean;
}

export function Chat({ data }: { data: IChat }) {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const [office, setOffice] = useState<Office | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [ticket, setTicket] = useState<Ticket | null>(data.ticket);

  useEffect(() => {
    api.offices.get(data.ticket?.office || 0)
      .then(response => {
        setOffice(response.data);
      })
      .catch(err => {
        console.log(err);
      });

    api.users.get(data.ticket?.user || 0)
      .then(response => {
        setUser(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, [data]);

  useEffect(() => {
    setTicket(data.ticket);
  }, [data]);

  const closeTicket = useCallback(() => {
    api.tickets.update(data.ticket?.id || 0,
      {
        title: data.ticket?.title,
        description: data.ticket?.description,
        category: data.ticket?.category,
        office: data.ticket?.office,
        status: "closed"
      })
      .then(response => {
        setTicket(response.data)
      })
      .catch(err => {
        console.log(err);
      })
  }, [data.ticket, ticket]);

  const openTicket = useCallback(() => {
    api.tickets.update(data.ticket?.id || 0, {
      title: data.ticket?.title,
      description: data.ticket?.description,
      category: data.ticket?.category,
      office: data.ticket?.office,
      status: "open"
    })
      .then(response => {
        setTicket(response.data);
      })
      .catch(err => {
        console.log(err);
      })
  }, [data.ticket, ticket]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [data.messages]);

  return (
    <div className="flex flex-col flex-grow gap-2 max-h-[calc(100vh-20%)] overflow-hidden">
      <span className="text-2xl font-bold">Обращение «{data.title}»</span>
      <div className="flex flex-row gap-2 overflow-hidden">
        <div className="flex flex-col p-2 flex-grow max-h-[calc(100vh-30%)] overflow-auto">
          {data.messages.map((message) => (
            <Message key={message.id} adminView={data.adminView} message={message} />
          ))}
          <div ref={messagesEndRef} />
        </div>
        {data.adminView && (
          <div className="flex flex-col gap-2 h-fit ml-4 bg-white dark:bg-zinc-900 p-8 rounded-xl">
            <div className='flex flex-row justify-between'>
              <div className='mb-4 flex flex-col'>
                <span className="font-semibold text-sm text-default-500">Описание:</span>
                <span className="font-semibold text-sm">{ ticket?.description || 'н/д' }</span>
              </div>
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    variant='bordered'
                    size='sm'
                  >
                    <FaEllipsis />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu aria-label='Действия с тикетом'>
                  {ticket?.status == "closed" ? (
                    <DropdownItem key="open" onClick={openTicket}>Открыть обращение</DropdownItem>
                  ) : (
                    <DropdownItem key="close" onClick={closeTicket}>Закрыть обращение</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
            <Divider className='mb-2' />
            <span className="font-semibold text-sm text-default-500">Пользователь:</span>
            <div className="flex flex-col gap-1 mb-2">
              <span className="font-semibold text-sm">{user?.first_name} {user?.last_name}</span>
              <span className="flex flex-row items-center gap-2 font-semibold text-sm text-primary-500"><FaPhone /> {user?.phone_number}</span>
            </div>
            <Divider className='mb-2' />
            <span className="font-semibold text-sm text-default-500">Локация:</span>
            <div className="flex flex-col mb-2">
              <span><LocationChip locationId={office?.location || 0} /></span>
            </div>
            <Divider className='mb-2' />
            <span className="font-semibold text-sm text-default-500">Офис:</span>
            <div className="flex flex-col">
              <span className="font-semibold text-sm">{office?.display_name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function getNormalizedUsername(username: string) {
  switch(username) {
    case 'user':
      return "Пользователь"
    case 'admin':
      return "Администратор"
  }
}

export function Message({ adminView, message }: { adminView: boolean, message: IMessage }) {
  return (
    <div className={`flex flex-col w-full items-${!adminView && message.isMe ? 'end' : 'start'}`}>
      <div className={`flex flex-col items-${!adminView && message.isMe ? 'end' : 'start'} gap-2 p-2 max-w-lg`}>
        <span className="text-base font-medium">
          {adminView ? getNormalizedUsername(message.user) : (message.isMe ? 'Вы' : getNormalizedUsername(message.user))}, {getNormalizedDate(message.date)}
        </span>
        <div className={`flex flex-row min-w-32 gap-2 px-3 py-2 ${!adminView && message.isMe ? 'bg-primary' : 'bg-white dark:bg-zinc-800'} rounded-xl`}>
          <span className={`text-base font-normal text-${!adminView && message.isMe ? 'right' : 'left'} text-${!adminView && message.isMe ? 'background' : 'foreground'}`}>
            {message.message}
          </span>
        </div>
      </div>
    </div>
  );
}
