import { NextUIProvider } from "@nextui-org/react";
import { Outlet, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify";

export function BaseLayout() {
  const navigate = useNavigate();

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <NextUIProvider navigate={navigate}>
        <Outlet />
      </NextUIProvider>
    </>

  )
}