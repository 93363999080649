import { Chip } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { OfficeLocation } from "../../../api/locations";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useAppSelector";

export function LocationChip({ locationId }: { locationId: number }) {
  const [item, setItem] = useState<OfficeLocation | null>(null);
  const items = useAppSelector(state => state.handbooks.location);

  useEffect(() => {
    setItem(items.find(el => el.id == locationId) || null);
  }, [items, locationId]);

  return (
    <>
      {item !== null && (
        <Chip as={Link} to={`/dashboard/location/${locationId}`} variant="dot" color="primary">{item?.display_name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};