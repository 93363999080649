import { Button, useDisclosure } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { TableBuilder } from "../../components/tables/tableBuilder";
import { FaExpand, FaPencil, FaPlus } from "react-icons/fa6";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { SharedLock } from "../../../api/sharedLocks";
import { EditSharedLockModal } from "../../components/modals/lock/editSharedLockModal";
import { CreateSharedLockModal } from "../../components/modals/lock/createSharedLockModal";
import { ColumnType } from "../../components/tables/tableTypes";

export function SharedLocksPage() {
  const [locks, setLocks] = useState<SharedLock[] | null>(null);
  const [lock, setLock] = useState<SharedLock | null>(null);

  const handleError = useErrorHandling();
  
  const createSharedLockModalDisclosure = useDisclosure();
  const editSharedLockModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editLock = (lock: SharedLock) => {
    setLock(lock);
    editSharedLockModalDisclosure.onOpen();
  }

  useEffect(() => {
    api.sharedLocks.list()
      .then(response => {
        setLocks(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    createSharedLockModalDisclosure.isOpen,
    editSharedLockModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateSharedLockModal disclosure={createSharedLockModalDisclosure} />
      <EditSharedLockModal disclosure={editSharedLockModalDisclosure} lock={lock} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Общие замки</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
                <FaExpand />
              </Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createSharedLockModalDisclosure.onOpen()}
              >
                Создать общий замок
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              displayOptions={false}
              removeWrapper={true}
              fullscreenDisclosure={fullscreenDisclosure}
              columns={[
                {
                  label: "Системный номер",
                  key: "id",
                  type: ColumnType.String,
                  sortable: true
                },
                {
                  label: "Замок",
                  key: "lock_id",
                  type: ColumnType.Lock,
                  sortable: true
                },
                {
                  label: "Локация",
                  key: "location_id",
                  type: ColumnType.Location,
                  sortable: true
                },
                {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editLock(row); }
                    }
                  ]
                }
              ]}
              data={locks || []}
              rowsPerPage={10}
            />
          </div>
        </div>
      </div>
    </>
  );
}
