import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { User } from "../../../../api/users";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { api } from "../../../../api";
import { getIdFromRenterHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IUserEditForm {
  username?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  phone_number?: string;
  role?: number;
  renter?: number;
  password?: string;
  telegram?: number;
}

const editUserFields: InputField[] = [
  { label: "Имя пользователя", name: "username", placeholder: "username", type: "text", isRequired: true },
  { label: "Имя", name: "first_name", placeholder: "Имя", type: "text", isRequired: true },
  { label: "Отчество", name: "middle_name", placeholder: "Отчество", type: "text", isRequired: false },
  { label: "Фамилия", name: "last_name", placeholder: "Фамилия", type: "text", isRequired: true },
  { label: "Номер телефона", name: "phone_number", placeholder: "+7955...", type: "text", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "Арендатор", type: "renter", isRequired: false },
  { label: "Пароль", name: "password", placeholder: "Пароль", type: "password", isRequired: true }
];

export function EditUserModal({ disclosure, user }: { disclosure: ReturnType<typeof useDisclosure>, user: User | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const navigate = useNavigate();
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);

  const handleError = useErrorHandling();

  const onSubmit = (data: IUserEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter));

      api.users.update(user!.id, {
        username: data.username,
        first_name: data.first_name,
        middle_name: data.middle_name,
        last_name: data.last_name,
        phone_number: data.phone_number,
        password: data.password,
        role: Number(1),
        renter: Number(renter)
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.users.delete(user!.id)
        .then(_data => {
          disclosure.onClose();
          navigate("/dashboard/users/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование пользователя"
      fields={editUserFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      deleteButtonText="Удалить из компании"
      defaultValues={{
        username: user?.username,
        first_name: user?.first_name,
        middle_name: user?.middle_name,
        last_name: user?.last_name,
        phone_number: user?.phone_number,
        role: user?.role,
        renter: user?.renter,
        telegram: user?.telegram
      }} />
  );
}
