import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { api } from "../../../api";
import { EditRentedOfficeModal } from "../modals/office/editRentedOfficeModal";
import { RentedOffice } from "../../../api/rentedOffices";

export function RentedOfficeChip({ displayName, rentedOfficeId }: { displayName: string, rentedOfficeId: number }) {
  const [item, setItem] = useState<RentedOffice | null>(null);
  const disclosure = useDisclosure();

  useEffect(() => {
    api.rentedOffices.get(rentedOfficeId)
      .then(res => {
        setItem(res.data);
      })
      .catch(err => {
        console.error("Ошибка при загрузке RentedOfficeChip: " + err);
      });
  }, [rentedOfficeId]);

  return (
    <>
      <EditRentedOfficeModal disclosure={disclosure} office={item!} />
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{ displayName }</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">{ displayName }</Chip>
      )}
    </>
  );
};