import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingSpinner } from "../loadingSpinner";
import { PageBuilder, ViewPage } from "./pageBuilder";
import { InputField } from "../modals/modalBuilder";
import { api } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { getIdFromLocationHandbook, getIdFromLockHandbook } from "../../../utils/getIdFromHandbooks";
import { SharedLock } from "../../../api/sharedLocks";
import { Column, ColumnType } from "../tables/tableTypes";

interface IEditSharedLockForm {
  lock_id: number;
  location_id: number;
}

const editSharedLockFields: InputField[] = [
  {
    label: "Замок",
    name: "lock_id",
    placeholder: "-",
    type: "lock",
    isRequired: true
  }, {
    label: "Локация",
    name: "location_id",
    placeholder: "-",
    type: "location",
    isRequired: true
  }
]

const viewItemColumns: Column[] = [
  {
    label: "Системный номер",
    key: "id",
    type: ColumnType.String
  },
  {
    label: "Номер замка",
    key: "lock_id",
    type: ColumnType.Lock
  },
  {
    label: "Локация",
    key: "location_id",
    type: ColumnType.Location
  }
];

export function ViewSharedLockPage() {
  const { id } = useParams();
  const [lock, setLock] = useState<SharedLock | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    api.sharedLocks.get(Number(id))
      .then(response => {
        setLock(response.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке данных замка!");
      });
  }, [id]);

  const onSubmit = async (data: IEditSharedLockForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const lockId = await getIdFromLockHandbook(String(data.lock_id));
      const locationId = await getIdFromLocationHandbook(String(data.location_id));

      api.sharedLocks.update(lock!.id, {
        lock_id: Number(lockId),
        location_id: Number(locationId)
      })
        .then(_data => {
          toast.success("Замок успешно обновлён!");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при редактировании замка!");
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.sharedLocks.delete(lock!.id)
        .then(_data => {
          navigate("/dashboard/locks/");
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          toast.error("Произошла ошибка при удалении замка!");
          resolve(false);
        });
    });
  };

  return (
    <>
      {!location && (
        <LoadingSpinner />
      )}
      {location && (
        <PageBuilder
          title="Замок"
          fields={editSharedLockFields}
          onSubmit={onSubmit}
          onDelete={onDelete}
          isSensitiveData={true}
          submitButtonText="Сохранить"
          viewEntityComponent={<ViewPage item={lock} columns={viewItemColumns} />}
          defaultValues={{
            lock_id: lock?.lock_id,
            location_id: lock?.location_id,
          }} />
      )}
    </>
  );
}
