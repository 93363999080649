import { Input, Button, useDisclosure, Tabs, Tab, Checkbox } from "@nextui-org/react";
import { Controller, RegisterOptions, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { OfficeHandbook } from "../handbooks/officeHandbook";
import { OfficeTypeHandbook } from "../handbooks/officeTypeHandbook";
import { RenterHandbook } from "../handbooks/renterHandbook";
import { UserHandbook } from "../handbooks/userHandbook";
import { LockSystemHandbook } from "../handbooks/lockSystemHandbook";
import { LockHandbook } from "../handbooks/lockHandbook";
import { UserRoleHandbook } from "../handbooks/userRoleHandbook";
import { ConfirmDeleteModal } from "../modals/service/confirmDeleteModal";
import { ConfirmEditModal } from "../modals/service/confirmEditModal";
import { LocationHandbook } from "../handbooks/locationHandbook";
import { CategoryHandbook } from "../handbooks/categoryHandbook";
import { InputField, ModalBuilderProps } from "../modals/modalBuilder";
import { Column } from "../tables/tableTypes";

export interface PageBuilderProps extends Omit<ModalBuilderProps, 'isOpen' | 'onOpenChange'> {
  viewEntityComponent: any;
}

export function PageBuilder({
  title,
  fields,
  defaultValues,
  isSensitiveData,
  submitButtonText = "Создать",
  onSubmit,
  onDelete,
  viewEntityComponent
}: PageBuilderProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const editSensitiveDisclosure = useDisclosure();
  const deleleleSensetiveDisclosure = useDisclosure();

  useEffect(() => {
    Object.keys(defaultValues || {}).forEach((key) => {
      setValue(key, defaultValues?.[key] || "");
    });
  }, [defaultValues]);

  const submit = (data: any) => {
    if (isSensitiveData) {
      setData(data);
      editSensitiveDisclosure.onOpen();
    } else {
      onSensitiveSubmit(data);
    }
  };

  const del = () => {
    if (isSensitiveData) {
      deleleleSensetiveDisclosure.onOpen();
    } else {
      onSensitiveDelete();
    }
  };

  const onSensitiveSubmit = (data: any) => {
    setLoading(true);
    onSubmit(data).then(_result => {
      setLoading(false);
    });
  }

  const onSensitiveDelete = () => {
    if (onDelete) {
      setLoading(true);
      onDelete().then(_result => {
        setLoading(false);
      });
    }
  }

  const getRegisterProps = (field: InputField) => {
    const props: RegisterOptions = {};

    if (field.isRequired) {
      props.required = "Это поле обязательно для заполнения";
    }

    return props;
  }

  const getBaseInputProps = (field: InputField) => {
    const props: any = Object.assign({}, register(field.name, getRegisterProps(field)));

    props.type = field.type;
    props.name = field.name;
    props.isInvalid = !!errors[field.name];
    props.errorMessage = errors[field.name]?.message;
    props.placeholder = field.placeholder;
    props.label = field.label;
    props.defaultValue = String(defaultValues?.[field.name] || "");
    props.isRequired = field.isRequired;

    return props;
  }

  const getHandbookProps = (field: InputField) => {
    // TODO write universal types for handbook props
    const props: any = {};

    props.register = register(field.name, getRegisterProps(field));
    props.isInvalid = !!errors[field.name];
    props.errorMessage = errors[field.name]?.message?.toString() || "";
    props.defaultSelectedKey = String(defaultValues?.[field.name] || "");

    return props;
  }

  const renderInput = (field: InputField) => {
    switch (field.type) {
      case 'text':
      case 'email':
      case 'datetime-local':
      case 'password':
        return (
          <Input
            {...getBaseInputProps(field)}
            variant="bordered"
          />
        );

      case 'boolean':
        return (
          <Controller
            control={control}
            name={field.name}
            render={({ field: controllerField }) => (
              <Checkbox
                {...controllerField}
                isSelected={controllerField.value}
                onChange={controllerField.onChange}
                isInvalid={!!errors[field.name]}
              >
                {field.label}
              </Checkbox>
            )}
          />
        );

      case 'office':
        return <OfficeHandbook
          {...getHandbookProps(field)}
        />;

      case 'office-type':
        return <OfficeTypeHandbook
          {...getHandbookProps(field)}
        />;

      case 'renter':
        return <RenterHandbook
          {...getHandbookProps(field)}
        />;

      case 'user':
        return <UserHandbook
          {...getHandbookProps(field)}
        />;

      case 'lock-system':
        return <LockSystemHandbook
          {...getHandbookProps(field)}
        />;

      case 'lock':
        return <LockHandbook
          {...getHandbookProps(field)}
        />;

      case 'user-role':
        return <UserRoleHandbook
          {...getHandbookProps(field)}
        />;

      case 'location':
        return <LocationHandbook
          {...getHandbookProps(field)}
        />;

      case 'category':
        return <CategoryHandbook
          {...getHandbookProps(field)}
        />;
    }
  };

  return (
    <>
      <ConfirmEditModal isOpen={editSensitiveDisclosure.isOpen} onOpenChange={editSensitiveDisclosure.onOpenChange} callback={onSensitiveSubmit} data={data} />
      <ConfirmDeleteModal isOpen={deleleleSensetiveDisclosure.isOpen} onOpenChange={deleleleSensetiveDisclosure.onOpenChange} callback={onSensitiveDelete} />

      <div className="p-4">
        <div className="flex flex-col justify-between mb-4">
          <div className="flex flex-col gap-4 mb-4">
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex flex-col gap-4">
            <Tabs>
              <Tab title="Просмотр информации">
                {viewEntityComponent}
              </Tab>
              <Tab title="Редактирование">
                <form onSubmit={handleSubmit(submit)}>
                  <div className="flex flex-col">
                    {fields.map((field, index) => (
                      <div key={index} className="mb-4 block">
                        {renderInput(field)}
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-row gap-4">
                    {onDelete && (
                      <Button color="danger" onClick={del} variant="flat">Удалить</Button>
                    )}
                    <Button color="success" type="submit" variant="flat" isLoading={loading}>{submitButtonText}</Button>
                  </div>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export function ViewPage({ item, columns }: { item: any | null, columns: Column[] }) {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col">
          {item !== null && (
            <>
              {columns.map(column => {
                return (
                  <div className="flex flex-col mb-4 gap-1" key={column.key}>
                    <span>{column.label}</span>
                    { /* @ts-ignore */}
                    <span className="text-lg font-medium">{renderCell(item, column)}</span>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </>
  )
}