import { Button, Input, Link } from "@nextui-org/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { api } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { motion } from "framer-motion";
import { DarkModeToggler } from "../components/darkmode/darkModeToggler";
import { RegisterAdminParams } from "../../api/auth/auth";

export function SignUpPage() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback((data: any) => {
    const nData = data as RegisterAdminParams;
    api.auth.register(nData)
      .then(_res => {
        console.log(_res.data.id);
        toast.success("Пользователь успешно зарегистрирован!");
        navigate("/");
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при регистрации пользователя!")
      })
  }, [navigate]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="relative flex flex-col items-center justify-center w-full h-screen bg-[url(/Bg.png)] bg-cover bg-center">
        <div className="absolute inset-0 bg-black bg-opacity-0 dark:bg-opacity-20 transition-all"></div>
        <div className="relative z-10 flex flex-col items-center justify-center w-full">
          <motion.div
            className="bg-background rounded-xl p-8 shadow flex flex-col gap-2 md:min-w-[515px]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <h2>Регистрация</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <Input
                label="Имя пользователя"
                placeholder="Введите имя пользователя"
                variant="bordered"
                {...register("username", { required: true })}
                className="mb-2"
                isInvalid={!!errors["username"]}
                errorMessage={errors["username"]?.message?.toString()}
              />
              <Input
                label="Пароль"
                placeholder="Введите пароль"
                variant="bordered"
                type="password"
                {...register("password", { required: true })}
                className="mb-2"
                isInvalid={!!errors["password"]}
                errorMessage={errors["password"]?.message?.toString()}
              />
              <Input
                label="Имя"
                placeholder="Введите имя"
                variant="bordered"
                {...register("first_name", { required: true })}
                className="mb-2"
                isInvalid={!!errors["first_name"]}
                errorMessage={errors["first_name"]?.message?.toString()}
              />
              <Input
                label="Фамилия"
                placeholder="Введите фамилию"
                variant="bordered"
                {...register("last_name", { required: true })}
                className="mb-2"
                isInvalid={!!errors["last_name"]}
                errorMessage={errors["last_name"]?.message?.toString()}
              />
              <Input
                label="Отчество"
                placeholder="Введите отчество"
                variant="bordered"
                {...register("middle_name", { required: false })}
                className="mb-2"
                isInvalid={!!errors["middle_name"]}
                errorMessage={errors["middle_name"]?.message?.toString()}
              />
              <Input
                label="Адрес почты"
                placeholder="Введите адрес электронной почты"
                variant="bordered"
                type="email"
                {...register("email", { required: true })}
                className="mb-2"
                isInvalid={!!errors["email"]}
                errorMessage={errors["email"]?.message?.toString()}
              />  
              <Input
                label="Номер телефона"
                placeholder="Введите номер телефона"
                variant="bordered"
                {...register("phone_number", { required: true })}
                className="mb-2"
                isInvalid={!!errors["phone_number"]}
                errorMessage={errors["phone_number"]?.message?.toString()}
              />  
              <div className="mt-4 flex gap-4">
                <Button color="primary" className="w-full font-semibold" type="submit">
                  Зарегистрироваться
                </Button>
              </div>
              <div className="mt-4 flex w-full flex-col items-center">
                <span>Уже есть аккаунт?</span>
                <Link href="/" className="font-medium" showAnchorIcon>Войдите в него</Link>
              </div>
            </form>
          </motion.div>
          {process.env.VITE_BUILD_BRANCH?.startsWith("dev") && (
            <motion.div
              className="flex flex-col items-center gap-2 mt-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <span className="text-sm text-zinc-600 font-medium">
                <a href={`https://github.com/Elasticwork-space/ElasticClient/commit/${process.env.VITE_COMMIT_HASH}`}>
                  {process.env.VITE_COMMIT_HASH} / {process.env.VITE_BUILD_BRANCH}
                </a>
              </span>
            </motion.div>
          )}
          <br />
          <DarkModeToggler collapsed={false} />
        </div>
      </div>
    </>
  );
}