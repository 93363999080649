import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";
import { Button, Input } from "@nextui-org/react";

interface PasswordChangePromptForm {
  password: string;
  password_repeat: string;
}

export function PasswordChangePrompt() {
  const {
    register,
    // handleSubmit,
    formState: { errors },
  } = useForm<PasswordChangePromptForm>({
    defaultValues: {},
  });

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex flex-col items-center justify-center w-full h-screen bg-[url(/Bg.png)] bg-cover bg-center">
        <motion.div
          className="bg-white rounded-xl p-8 shadow flex flex-col gap-2 md:min-w-[515px]"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <h2>Восстановление пароля</h2>
          <Input
            label="Введите пароль"
            placeholder="Введите пароль"
            variant="bordered"
            type="password"
            {...register("password", { required: true })}
            className="mb-2"
            isInvalid={!!errors["password"]}
            errorMessage={errors["password"]?.message?.toString()}
          />
          <Input
            label="Введите пароль (ещё раз)"
            placeholder="Введите пароль"
            variant="bordered"
            type="password"
            {...register("password_repeat", { required: true })}
            className="mb-2"
            isInvalid={!!errors["password_repeat"]}
            errorMessage={errors["password_repeat"]?.message?.toString()}
          />
          <span className="max-w-96">Введите новый пароль для аккаунта и сохраните его в надёжном месте</span>
          <div className="mt-4 flex gap-4">
            <Button color="primary" className="w-full font-semibold" type="submit" onClick={() => toast.success("Пароль успешно изменён!")}>
              Сохранить
            </Button>
          </div>
        </motion.div>
        {process.env.VITE_BUILD_BRANCH?.startsWith("dev") && (
          <motion.div
            className="flex flex-col items-center gap-2 mt-4"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <span className="text-sm text-zinc-600 font-medium">
              <a href={`https://github.com/Elasticwork-space/ElasticAdmin/commit/${process.env.VITE_COMMIT_HASH}`}>
                {process.env.VITE_COMMIT_HASH} / {process.env.VITE_BUILD_BRANCH}
              </a>
            </span>
          </motion.div>
        )}
        <br />
      </div>
    </>
  );
}
