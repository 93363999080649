import { Button, Divider, Link, useDisclosure } from "@nextui-org/react";
import { FaBuilding, FaCity, FaMapPin, FaPenToSquare, FaPlus, FaRuler, FaTrainSubway } from "react-icons/fa6";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { useEffect, useState } from "react";
import { BusinessProfile } from "../../../api/businessProfiles";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { EditBusinessProfileModal } from "../../components/modals/businessProfile/editBusinessProfileModal";
import { OfficeLocation } from "../../../api/locations";
import { CreateLocationModal } from "../../components/modals/location/createLocationModal";
import useErrorHandling from "../../../hooks/useErrorHandling";
import { sortAlphabeticallyByKey } from "../../../utils";

export function DashboardDemo() {
  const [businessProfileData, setBusinessProfileData] = useState<BusinessProfile | null>(null);
  const [locations, setLocations] = useState<OfficeLocation[] | null>(null);

  const handleError = useErrorHandling();

  const businessProfile = useAppSelector(state => state.businessProfile.businessProfile);

  const editBusinessProfileModalDisclosure = useDisclosure();
  const createLocationModalDisclosure = useDisclosure();

  useEffect(() => {
    if (businessProfile.id == 0) return;

    api.businessProfiles.get(businessProfile.id)
      .then(response => {
        setBusinessProfileData(response.data);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
        localStorage.removeItem("businessProfileId");
      });

    api.businessProfiles.getLocations(businessProfile.id)
      .then(response => {
        const sortedData = sortAlphabeticallyByKey(response.data, "display_name");
        setLocations(sortedData);
      })
      .catch(err => {
        console.log(err);
        const { errorMessage } = handleError(err);
        toast.error(errorMessage);
      });
  }, [
    businessProfile,
    editBusinessProfileModalDisclosure.isOpen,
    createLocationModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateLocationModal disclosure={createLocationModalDisclosure} />
      <EditBusinessProfileModal disclosure={editBusinessProfileModalDisclosure} businessProfile={businessProfileData} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex flex-col gap-2 max-md:flex-grow">
          <Link href={`/dashboard`} className="flex flex-row items-center p-4 rounded-xl font-medium text-primary bg-primary bg-opacity-10">
            Мой бизнес-профиль
          </Link>
          <Link href={`/dashboard/requisites`} className="flex flex-row items-center p-4 rounded-xl font-medium text-foreground-600">
            Счета
          </Link>
        </div>
        <div className="flex-grow flex flex-col gap-2">
          <div className="w-full flex flex-col gap-4 bg-white dark:bg-zinc-900 p-4 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            {businessProfileData && (
              <div className="w-full h-full flex flex-col gap-2">
                {/* <div className="w-full min-h-[290px] bg-gray-500 bg-opacity-50 rounded-xl" /> */}
                <div className="flex flex-col gap-2 mb-2">
                  <div className="flex flex-row gap-4 items-center mb-2 justify-between">
                    <span className="font-medium">{businessProfileData.display_name}</span>
                    <Button startContent={<FaPenToSquare />} size="sm" color="primary" className="max-w-fit" onClick={() => editBusinessProfileModalDisclosure.onOpen()}>Редактировать</Button>
                  </div>
                  <div className="flex flex-col p-4 gap-3 rounded-xl bg-zinc-100 dark:bg-zinc-950 border-2 border-foreground border-opacity-10 max-w-[512px] mb-2">
                    <span className="text-sm text-primary">{businessProfileData.legal_address}</span>
                    { /* TODO */}
                    <span className="text-sm text-foreground-500">Нужно обсудить, будем ли мы добавлять описание или просто забьём.</span>
                    <Divider />
                    <span className="text-sm text-foreground-500">ИНН: {businessProfileData.inn}</span>
                    <span className="text-sm text-foreground-500">ОГРН: {businessProfileData.ogrn}</span>
                  </div>
                </div>
                <Divider className="mt-2 mb-2" />
                <div className="flex flex-col gap-2 mb-2">
                  <div className="flex flex-row gap-4 items-center mb-2 justify-between">
                    <span className="font-semibold">Локации</span>
                    <Button startContent={<FaPlus />} size="sm" color="primary" variant="solid" className="max-w-fit" onClick={() => createLocationModalDisclosure.onOpen()}>Создать</Button>
                  </div>
                  {locations && locations.length > 0 && (
                    <div className="flex flex-row flex-wrap gap-3 items-center mt-2">
                      {locations.map((location, index) => (
                        <Link href={`/dashboard/location/${location.id}`} className="flex flex-col bg-background border-2 border-default-200 rounded-xl cursor-pointer select-none text-foreground" key={index}>
                          <div className="flex flex-col gap-3 p-4">
                            <span className="text-medium font-bold">{location.display_name}</span>
                            <div className="flex flex-row gap-2 items-center">
                              <FaCity />
                              <span className="text-sm">{location.city}</span>
                            </div>
                            <div className="flex flex-row gap-2 items-center">
                              <FaMapPin />
                              <span className="text-sm">{location.address}</span>
                            </div>
                            {location.district && (
                              <div className="flex flex-row gap-2 items-center">
                                <FaBuilding />
                                <span className="text-sm">{location.district}</span>
                              </div>
                            )}
                            {location.metro_station && (
                              <div className="flex flex-row gap-2 items-center">
                                <FaTrainSubway />
                                <span className="text-sm">{location.metro_station}</span>
                              </div>
                            )}
                            <div className="flex flex-row gap-2 items-center">
                              <FaRuler />
                              <span className="text-sm">{location.total_area} м2</span>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  )}
                  {locations?.length == 0 && (
                    <span>Похоже, в вашем бизнес-профиле ещё нет ни одной локации. Создайте первую!</span>
                  )}
                </div>
              </div>
            )}
            {!businessProfileData && (
              <span>Идёт загрузка данных, подождите</span>
            )}
          </div>
        </div>
      </div>
    </>
  )
}