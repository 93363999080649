import { useDisclosure } from "@nextui-org/react";
import { InputField, ModalBuilder, ModalError } from "./../modalBuilder";
import { RentedOffice } from "../../../../api/rentedOffices";
import { toast } from "react-toastify";
import { api } from "../../../../api";
import { getIdFromRenterHandbook, getIdFromOfficeHandbook } from "../../../../utils/getIdFromHandbooks";
import useErrorHandling from "../../../../hooks/useErrorHandling";
import { useState } from "react";

interface IRentedOfficeEditForm {
  office: number;
  renter: number;
  price: number;
  description: string;
  start_date: string;
  end_date: string;
  deposit: number;
}

const editRentedOfficeFields: InputField[] = [
  { label: "Офис", name: "office", placeholder: "Офис", type: "office", isRequired: true },
  { label: "Арендатор", name: "renter", placeholder: "Арендатор", type: "renter", isRequired: true },
  { label: "Цена", name: "price", placeholder: "Цена", type: "text", isRequired: true },
  { label: "Описание", name: "description", placeholder: "Описание", type: "text", isRequired: true },
  { label: "Дата начала", name: "start_date", placeholder: "Дата начала", type: "datetime-local", isRequired: true },
  { label: "Дата окончания", name: "end_date", placeholder: "Дата окончания", type: "datetime-local", isRequired: true },
  { label: "Депозит", name: "deposit", placeholder: "Депозит", type: "text", isRequired: true },
];

export function EditRentedOfficeModal({ disclosure, office }: { disclosure: ReturnType<typeof useDisclosure>, office: RentedOffice | null }) {
  const { isOpen, onOpenChange } = disclosure;
  const [modalErrors, setModalErros] = useState<ModalError[]>([]);
  
  const handleError = useErrorHandling();

  const onSubmit = (data: IRentedOfficeEditForm) => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      const renter = await getIdFromRenterHandbook(String(data.renter));
      const officeId = await getIdFromOfficeHandbook(String(data.office));

      api.rentedOffices.update(office!.id, {
        office: Number(officeId),
        renter: Number(renter),
        price: data.price,
        description: data.description,
        start_date: data.start_date,
        end_date: data.end_date,
        deposit: data.deposit
      })
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  }

  const onDelete = () => {
    return new Promise<Boolean>(async (resolve, _reject) => {
      api.rentedOffices.delete(office!.id)
        .then(_data => {
          disclosure.onClose();
          resolve(true);
        })
        .catch(err => {
          console.log(err);
          const { errorMessage, parsedErrors } = handleError(err);
          setModalErros(parsedErrors || []);
          toast.error(errorMessage);
          resolve(false);
        });
    });
  };

  return (
    <ModalBuilder
      title="Редактирование арендованного офиса"
      fields={editRentedOfficeFields}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      modalErrors={modalErrors}
      onSubmit={onSubmit}
      onDelete={onDelete}
      isSensitiveData={true}
      submitButtonText="Сохранить"
      defaultValues={{
        office: office?.office,
        renter: office?.renter,
        price: office?.price,
        description: office?.description,
        start_date: office?.start_date,
        end_date: office?.end_date,
        deposit: office?.deposit
      }} />
  );
}
