import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface PreloadResponse {
  variables: string[]
}

export interface TemplateCreateParams {
  file: File,
  display_name: string;
  variables: {
    [key: string]: string;
  }
}

export interface TemplateCreateResponse {
  template_id: number;
}

export interface TemplateFillParams {
  template_id: number;
  values: {
    [key: string]: string;
  }
}

export interface Template {
  id: number;
  display_name: string;
  template_link: string;
}

export interface TemplateVariable {
  id: number;
  document_template: number;
  variable: string;
  description: string;
}

export interface TemplateFillResponse {
  result: string;
  document_link: string;
}

export function DocumentationTemplatesAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/documentation/templates/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<Template[]>;
    },
    getTemplateVariables: async (templateId: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/documentation/templates/${templateId}/variables/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<{ variables: TemplateVariable[] }>;
    },
    fillTemplate: async (params: TemplateFillParams) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/documentation/templates/${params.template_id}/?business_profile_id=${businessProfileId}`, params.values, true) as TemplateFillResponse;
    },
    preload: async (docx: File) => {
      const formBody = new FormData();
      formBody.append("file", docx);
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/documentation/templates/preload/?business_profile_id=${businessProfileId}`, formBody, true, "auto") as ApiResponse<PreloadResponse>;
    },
    createTemplate: async (params: TemplateCreateParams) => {
      const formBody = new FormData();
      formBody.append("file", params.file);
      formBody.append("template_data", JSON.stringify(params));
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/documentation/templates/?business_profile_id=${businessProfileId}`, formBody, true, "auto") as ApiResponse<TemplateCreateResponse>;
    }
  }
}
