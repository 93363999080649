import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";

export interface Month {
  id: string;
  display_name: string;
}

export function NewMonthHandbook(props: AutocompleteProps<Month>) {
  const items: Month[] = [
    { id: 'Январь', display_name: 'Январь' },
    { id: 'Февраль', display_name: 'Февраль' },
    { id: 'Март', display_name: 'Март' },
    { id: 'Апрель', display_name: 'Апрель' },
    { id: 'Май', display_name: 'Май' },
    { id: 'Июнь', display_name: 'Июнь' },
    { id: 'Июль', display_name: 'Июль' },
    { id: 'Август', display_name: 'Август' },
    { id: 'Сентябрь', display_name: 'Сентябрь' },
    { id: 'Октябрь', display_name: 'Октябрь' },
    { id: 'Ноябрь', display_name: 'Ноябрь' },
    { id: 'Декабрь', display_name: 'Декабрь' },
  ];

  return (
    <Autocomplete
      defaultItems={items}
      label="Месяц"
      placeholder="Выберите месяц"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={item.id}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function MonthHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = [
    { id: 'Январь', display_name: 'Январь' },
    { id: 'Февраль', display_name: 'Февраль' },
    { id: 'Март', display_name: 'Март' },
    { id: 'Апрель', display_name: 'Апрель' },
    { id: 'Май', display_name: 'Май' },
    { id: 'Июнь', display_name: 'Июнь' },
    { id: 'Июль', display_name: 'Июль' },
    { id: 'Август', display_name: 'Август' },
    { id: 'Сентябрь', display_name: 'Сентябрь' },
    { id: 'Октябрь', display_name: 'Октябрь' },
    { id: 'Ноябрь', display_name: 'Ноябрь' },
    { id: 'Декабрь', display_name: 'Декабрь' },
  ];

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Месяц"
      placeholder="Выберите месяц"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}