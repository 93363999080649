import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface AdminNotification {
  id: number;
  is_read: boolean;
  created_at: string;
  message: string;
  title: string;
  business_profile_id: number;
}

export function NotificationsAPI(baseUrl: string) {
  return {
    list: async () => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/notifications/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminNotification[]>;
    },
    get: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.get(`${baseUrl}/admin/notifications/${id}/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminNotification>;
    },
    markAsReaded: async (id: number) => {
      const businessProfileId = rootStore.getState().businessProfile.businessProfile.id;
      return await crud.post(`${baseUrl}/admin/notifications/${id}/read/?business_profile_id=${businessProfileId}`, undefined, true) as ApiResponse<AdminNotification>;
    }
  }
}
